import EventEmitter from 'events';
import autoBind from 'auto-bind';

const TARGET_LATENCY = 0.75;

/* This wraps the HTML5 audio component with some logic
 * for handling our audio offsets so that duration and
 * currentTime are handled correctly.
 *
 * The other reason this was needed is that audio elements
 * can only have a single listener, so this hooks up listeners
 * and forwards the events an event emitter which can have
 * many listeners.
 */
export class AudioPlayer extends EventEmitter {
    constructor() {
        super();
        autoBind(this);

        this.loaded = false;
        this.uri = null;
        this.eventId = null;
        this.offset = 0;
        this.defaultOffset = 0;
        this.metaData = null;
        this.failedToLoad = false;
        this.initAudio();
    }

    initAudio() {
        this.audio = document.createElement('audio');
        this.audio.setAttribute('preload', 'metadata');
        this.audio.controls = false;

        this.audio.onloadedmetadata = this.onLoadMetaData;
        this.audio.ondurationchange = this.onDurationChange;
        this.audio.ontimeupdate = this.onTimeChange;
        this.audio.onplaying = this.onStatusChange;
        this.audio.onpause = this.onStatusChange;
        this.audio.onended = this.onEnded;
        this.initShaka(this.audio);
    }

    initShaka(audioEl) {
        window.shaka.polyfill.installAll();
        if (window.shaka.Player.isBrowserSupported()) {
            const localPlayer = new window.shaka.Player(audioEl);
            const audioContainer = document.createElement('div');
            const ui = new window.shaka.ui.Overlay(localPlayer, audioContainer, audioEl);

            const controls = ui.getControls();
            const player = controls.getPlayer();
            const media = player.getMediaElement();

            player.configure({
                streaming: {
                    rebufferingGoal: 1,
                    bufferingGoal: 2,
                    lowLatencyMode: true,
                    useNativeHlsOnSafari: true,
                    stallEnabled: true,
                    retryParameters: {
                        maxAttempts: 2, // the maximum number of requests before we fail
                        baseDelay: 1000, // the base delay in ms between retries
                        backoffFactor: 2, // the multiplicative backoff factor between retries
                        fuzzFactor: 0.5 // the fuzz factor to apply to each retry delay
                    }
                },
                manifest: {
                    availabilityWindowOverride: 600000,
                    defaultPresentationDelay: 5,
                    retryParameters: {
                        timeout: 30000, // timeout in ms, after which we abort
                        stallTimeout: 5000, // stall timeout in ms, after which we abort
                        connectionTimeout: 10000, // connection timeout in ms, after which we abort
                        maxAttempts: 2, // the maximum number of requests before we fail
                        baseDelay: 1000, // the base delay in ms between retries
                        backoffFactor: 2, // the multiplicative backoff factor between retries
                        fuzzFactor: 0.5 // the fuzz factor to apply to each retry delay
                    }
                }
            });

            // Attach player to the window to make it easy to access in the JS console.
            this.player = player;
            this.audio = media;
            const thisObj = this;

            // handle shaka player when missing audio segs are missing
            // this is rare because the manfiest should have listed
            // only the valid existing segments.
            //
            // added a bit logic to decide the seek value so we don’t
            // request the same audio segment repeatdely.
            // as example: if we are request a seg and it is not available then
            // we need add 4 seconds to request the next one.
            this.player.addEventListener('error', event => {
                const modValue = Math.floor(thisObj.currentTime % 4);
                let seekValue = null;

                if (!modValue) {
                    seekValue = 1;
                } else {
                    seekValue = 5 - modValue;
                }
                if (event && event.detail.code === 1001 && seekValue) {
                    thisObj.seek(thisObj.currentTime + seekValue);
                }
            });
        }
    }

    removeAudio() {
        this.audio.remove();
    }

    /* state properties */
    get paused() {
        return this.audio.paused;
    }

    get currentTime() {
        return Math.max(0, this.audio.currentTime - this.offset);
    }

    get duration() {
        const dur = this.player ? this.player.seekRange().end : this.audio.duration;
        return Math.max(0, dur - this.offset);
    }

    get playbackRate() {
        return this.audio.playbackRate;
    }

    get hasLoaded() {
        return this.loadedMetaData;
    }

    /* The Audio API */
    async load(uri, offset, metaData, defaultOffset) {
        const { isLive, id } = metaData;
        let mimeType;
        this.offset = offset || 0;
        this.defaultOffset = defaultOffset || 0;
        this.metaData = metaData;

        // We're using our media proxy for finished
        // calls, and the proxy requires us
        // to set a mimeType for shaka, or it won't load
        if (!isLive) {
            mimeType = 'audio/mpeg';
        }
        if (!this.loading || this.eventId !== id || (this.eventId === id && this.uri !== uri && this.paused)) {
            try {
                this.loading = true;
                this.uri = uri;
                this.eventId = id;
                await this.player
                    .load(uri, null, mimeType)
                    .then(() => {
                        this.loaded = true;
                        this.uri = uri;
                        this.eventId = id;
                    })
                    .catch(err => {
                        this.failedToLoad = true;
                        // eslint-disable-next-line no-console
                        console.log('Failure to load asset or manifest', err);
                    });
            } catch (error) {
                this.failedToLoad = true;
                // eslint-disable-next-line no-console
                console.log('Failure to load asset or manifest.', error);
            }
        }
    }

    play(uri, offset, metaData, defaultOffset) {
        return this.load(uri, offset, metaData, defaultOffset).then(() => {
            if (this.loaded) {
                if (metaData.isLive && !this.player.isLive()) {
                    this.failedToLoad = true;
                    return false;
                }

                this.playing = true;
                this.audio.play();
                return true;
            }
            return false;
        });
    }

    live() {
        this.seek(this.duration - TARGET_LATENCY, false, false);
        this.emit('seek', {
            adjusted: this.audio.currentTime - this.offset,
            raw: this.audio.currentTime,
            live: true
        });

        if (this.player.isLive()) {
            this.setPlaybackRate(1);
            this.player.goToLive();
        }
    }

    setPlaybackRate(rate) {
        this.audio.playbackRate = rate;
        if (this.player && !this.failedToLoad) {
            this.player.trickPlay(rate);
        }

        this.onStatusChange();
    }

    setVolume(volume) {
        if (volume > 0) {
            this.audio.volume = volume;
        }
    }

    pause() {
        this.audio.pause();
    }

    stop() {
        this.playing = false;
        this.audio.pause();
        this.onStatusChange();
    }

    reset() {
        this.stop();
    }

    // raw = true means set the time as is, without applying
    // the offset.
    // tryToNormalize means if raw is true,
    // but the defaultOffset and the normal offset don't match
    // we should use the difference between them
    seek(currentTime, raw, emitEvent = true, tryToNormalize) {
        if (!this.loadedMetaData) {
            this.once('loadedMetaData', () => this.seek(currentTime, raw, emitEvent, tryToNormalize));
        } else {
            let offset = 0;
            if (raw && this.offset !== this.defaultOffset && tryToNormalize) {
                offset = this.offset - this.defaultOffset;
            } else if (!raw) {
                offset = this.offset !== this.defaultOffset ? this.offset - this.defaultOffset : this.defaultOffset;
            }

            // if hls live stream, reset playbackrate when seeking
            const assetUri = this.player?.getAssetUri?.();
            if (assetUri && assetUri.substring(assetUri.length - 4) === 'm3u8' && this.playbackRate > 1) {
                this.setPlaybackRate(1);
            }
            // The UI could sometimes generate negative value (slider), we need to make sure
            // currentTime is always >=0
            let validCurrentTime = currentTime;
            if (currentTime < 0) {
                validCurrentTime = 0;
            }
            this.audio.currentTime = (validCurrentTime || 0) + offset;
        }

        if (emitEvent) {
            this.emit('seek', {
                adjusted: this.audio.currentTime - this.offset,
                raw: this.audio.currentTime
            });
        }
    }

    /* Event handlers, event forwarding */
    onLoadMetaData() {
        if (!this.loadedMetaData) {
            this.loadedMetaData = true;
            this.seek(0, undefined, false);
        }
        this.emit('durationChange', this.duration);
        this.emit('loadedMetaData');
    }

    onDurationChange() {
        this.emit('durationChange', this.duration);
    }

    onTimeChange() {
        this.emit('timeChange', this.currentTime);

        if (this.player.isLive()) {
            const isAtEnd = this.duration - this.currentTime < 5;
            // Caught up to live
            // Reset playback rate to 1
            if (isAtEnd && this.playbackRate > 1) {
                this.setPlaybackRate(1);
            }
        }
    }

    onStatusChange() {
        this.emit('statusChange');
    }

    onEnded() {
        this.seek(0);
        this.pause();
    }
}
