import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { Hint } from 'components/Hint';
import { RecommendedEvent } from './RecommendedEvent';
import { Player } from './Player';
import { styleSheet } from './stylesheet';

class AudioPlaybar extends PureComponent {
    static propTypes = {
        animateTabList: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool,
        showEventInfo: PropTypes.bool.isRequired,
        showRecommendedEvents: PropTypes.bool.isRequired,
        showSavedTabs: PropTypes.bool.isRequired,
        showVolume: PropTypes.bool.isRequired,
        maximizeTab: PropTypes.func.isRequired,
        recommendedEvents: PropTypes.arrayOf(PropTypes.any),
        removeTab: PropTypes.func.isRequired,
        savedTabs: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired
    };

    static defaultProps = {
        isPublic: false,
        recommendedEvents: []
    };

    constructor(props) {
        super(props);

        this.renderTabList = this.renderTabList.bind(this);
        this.renderPlaylist = this.renderPlaylist.bind(this);
    }

    renderTabList({ hideTooltip }) {
        const { savedTabs, styles, maximizeTab, removeTab, theme } = this.props;
        return savedTabs.map(({ title, id }) => (
            <Div
                styles={styles.tabListOption}
                key={id}
                onClick={e => {
                    maximizeTab(id);
                    hideTooltip(e);
                }}
            >
                <Text>{title}</Text>
                <Div
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeTab(id);
                        if (savedTabs.length === 1) {
                            hideTooltip(e);
                        }
                    }}
                >
                    <Icon type="circleX" color={theme.colors.white01} />
                </Div>
            </Div>
        ));
    }

    renderPlaylist({ hideTooltip }) {
        const { recommendedEvents } = this.props;

        return recommendedEvents.map(event => {
            const { id, title, equity, callDate } = event;

            return (
                <RecommendedEvent
                    callDate={callDate}
                    audioCallId={id}
                    eventId={id}
                    key={id}
                    title={title}
                    equity={equity}
                    hideTooltip={hideTooltip}
                />
            );
        });
    }

    render() {
        const {
            animateTabList,
            isPublic,
            recommendedEvents,
            savedTabs,
            showEventInfo,
            showRecommendedEvents,
            showSavedTabs,
            showVolume,
            styles,
            theme
        } = this.props;
        const tabListStyles = animateTabList ? { ...styles.tabList, ...styles.tabListAnimation } : styles.tabList;
        return (
            <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                <Div styles={styles.container} className="audio-playbar print-hide">
                    {showRecommendedEvents && (
                        <Tooltip
                            isEnabled={recommendedEvents && recommendedEvents.length > 0}
                            hideOnScroll={false}
                            blockBackground
                            useElementOffsetTop
                            useElementOffsetLeft
                            growUp
                            xOffset={-6}
                            yOffset={0}
                            persistOnMouseExit
                            content={this.renderPlaylist}
                            styles={styles.playlistMenu}
                            cancelClassName="audio-playbar-tools"
                            keepInViewport
                        >
                            {({ showTooltip }) => (
                                <Div styles={styles.playlist} onClick={showTooltip}>
                                    <Text size={1}>Suggested Playlist</Text>
                                    <Icon type="chevron02" styles={styles.chevron} color={theme.colors.gray04} />
                                </Div>
                            )}
                        </Tooltip>
                    )}
                    <Player isPublic={isPublic} showEventInfo={showEventInfo} showVolume={showVolume} />
                    {showSavedTabs && (
                        <Tooltip
                            isEnabled={savedTabs && savedTabs.length > 0}
                            blockBackground
                            useElementOffsetTop
                            useElementOffsetRight
                            growUp
                            growLeft
                            xOffset={0}
                            yOffset={0}
                            persistOnMouseExit
                            content={this.renderTabList}
                            styles={styles.tabListMenu}
                            cancelClassName="audio-playbar-tools"
                            hideOnScroll={false}
                        >
                            {({ showTooltip }) => (
                                <Hint
                                    disabled={savedTabs && savedTabs.length > 0}
                                    growLeft
                                    fromLeft
                                    xOffset={-4}
                                    yOffset={-30}
                                    text="Minimize a tab to save it"
                                    styles={tabListStyles}
                                    onClick={showTooltip}
                                >
                                    <Icon type="windows" color={theme.colors.gray04} />
                                    {savedTabs && savedTabs.length > 0 && (
                                        <Fragment>
                                            <Text size={1}>{savedTabs.length} Saved Tabs</Text>
                                            <Icon
                                                type="chevron02"
                                                styles={styles.chevron}
                                                color={theme.colors.gray04}
                                            />
                                        </Fragment>
                                    )}
                                </Hint>
                            )}
                        </Tooltip>
                    )}
                </Div>
            </MediaQuery>
        );
    }
}

export const AudioPlaybarUI = compose(withStyleSheet(styleSheet))(AudioPlaybar);
