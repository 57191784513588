import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Dropdown } from 'components/Dropdown';
import { Graphic } from 'components/Graphic';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { LoaderLogo } from 'components/LoaderLogo';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { RawHTML } from 'components/RawHTML';
import { Text } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { VideoPlayer } from 'components/VideoPlayer';
import { WithPermission } from 'components/WithPermission';
import { PERMISSIONS, VIDEO_TYPES } from 'consts';
import { toDurationString, getHighlightColor, toDateTimeString } from 'utils';
import { EventActionMenu } from '../EventActionMenu';
import { styleSheet } from './stylesheet';

class EventHighlights extends PureComponent {
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        hasHighlights: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        manageShareSettings: PropTypes.func.isRequired,
        matches: PropTypes.arrayOf(PropTypes.any).isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        openExport: PropTypes.func.isRequired,
        openVideoModal: PropTypes.func.isRequired,
        pageId: PropTypes.string,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        selectedRef: PropTypes.objectOf(PropTypes.any).isRequired,
        setFilter: PropTypes.func.isRequired,
        setSort: PropTypes.func.isRequired,
        setVisibility: PropTypes.func.isRequired,
        shareBookmarks: PropTypes.bool.isRequired,
        sortKey: PropTypes.string.isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        userId: PropTypes.string,
        videoPlayerRef: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        hasHighlights: false,
        pageId: undefined,
        passedStyles: {},
        userId: undefined,
        videoPlayerRef: undefined
    };

    constructor(props) {
        super(props);

        this.renderSettings = this.renderSettings.bind(this);
    }

    renderHeader() {
        const { openExport, styles, theme, filterKey, sortKey, setFilter, setSort, matches } = this.props;
        return (
            <Div styles={styles.header}>
                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                    <Dropdown
                        name="filterHighlights"
                        size={1}
                        cancelClassName="tab-tooltip"
                        disableHoverOpen
                        styles={styles.filterDropdown}
                        onChange={setFilter}
                        hideOnScroll={false}
                        options={[
                            { value: 'all', label: 'All Highlights' },
                            { value: 'team', label: 'Team Highlights' },
                            { value: 'me', label: 'My Highlights' }
                        ]}
                        value={filterKey}
                    />
                </WithPermission>
                <Dropdown
                    name="sortHighlights"
                    size={1}
                    cancelClassName="tab-tooltip"
                    disableHoverOpen
                    styles={styles.sortDropdown}
                    onChange={setSort}
                    options={[
                        { value: 'chron', label: 'Chronological' },
                        { value: 'newest', label: 'Newest' }
                    ]}
                    hideOnScroll={false}
                    value={sortKey}
                />
                <Div styles={styles.spacer} />
                <Hint
                    xOffset={-70}
                    text={matches && matches.length > 0 ? 'Export Highlights' : 'No Highlights to Export'}
                    description={matches && matches.length > 0 ? 'PDF or Word Doc' : undefined}
                    styles={matches && matches.length > 0 ? styles.settingsButton : styles.settingsButtonDisabled}
                    onClick={matches && matches.length > 0 ? openExport : undefined}
                    name="open-event-pdf-highlight-exporter"
                >
                    <Icon type="download" color={theme.colors.gray04} />
                </Hint>
                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                    <Tooltip
                        xOffset={0}
                        yOffset={-2}
                        isEnabled
                        useElementOffsetRight
                        useElementOffsetTop
                        growLeft
                        content={this.renderSettings}
                        persistOnMouseExit
                        useOutsideClickHandler
                        hideOnScroll={false}
                    >
                        {({ showTooltip }) => (
                            <Hint
                                xOffset={-50}
                                text="Share Settings"
                                styles={styles.settingsButton}
                                onClick={showTooltip}
                            >
                                <Icon type="gear02" color={theme.colors.gray04} />
                            </Hint>
                        )}
                    </Tooltip>
                </WithPermission>
            </Div>
        );
    }

    renderSettings() {
        const { styles, theme, manageShareSettings, shareBookmarks } = this.props;
        const shareValue = shareBookmarks ? 'share' : 'private';
        return (
            <Div styles={styles.settings} className="tab-tooltip">
                <Div styles={styles.settingsHeader}>
                    <Text size={3} weight="medium">
                        Your Highlights
                    </Text>
                    <Text size={1} styles={styles.headerSubtext}>
                        Applied to this event only
                    </Text>
                </Div>
                <MultiSelect styles={styles.options} selected={[shareValue]} required onChange={manageShareSettings}>
                    <Option styles={styles.option} type="radio" value="share">
                        <Icon type="eye" color={theme.colors.gray04} />
                        <Div styles={styles.optionText}>
                            <Text styles={styles.optionTitle} size={1} weight="medium">
                                Share with team
                            </Text>
                            <Text size={1}>
                                Your team can see your highlights on <strong>this event</strong>.
                            </Text>
                        </Div>
                    </Option>
                    <Option styles={styles.option} type="radio" value="private">
                        <Icon type="eyeStrike" color={theme.colors.gray04} />
                        <Div styles={styles.optionText}>
                            <Text styles={styles.optionTitle} size={1} weight="medium">
                                Keep private
                            </Text>
                            <Text size={1}>
                                Only you can see your highlights on <strong> this event</strong>.
                            </Text>
                        </Div>
                    </Option>
                </MultiSelect>
            </Div>
        );
    }

    renderHighlights() {
        const {
            filterKey,
            pageId,
            matches,
            mediaPlayer,
            onNodeSelect,
            selectedRef,
            setVisibility,
            sortKey,
            styles,
            theme,
            userId
        } = this.props;

        return matches.map(h => {
            const {
                created,
                creatorName,
                eventItemId,
                hasUnknownTime,
                highlight,
                highlightColor,
                id,
                note,
                shared,
                speakerAffiliation,
                speakerName,
                speakerTitle,
                startMs,
                startTimestamp,
                tags,
                transcriptionAudioOffsetSeconds,
                userId: creatorId
            } = h;
            const highlightBorder = getHighlightColor(highlightColor)?.dark;
            const highlightStyles = {
                ...styles.highlightedText,
                backgroundColor: getHighlightColor(highlightColor)?.color,
                borderBottom: `1px solid ${highlightBorder}`
            };
            const hasTime = startMs - 1000 * transcriptionAudioOffsetSeconds > 0;
            const startTime = Math.max(parseInt(startMs || 0, 10) / 1000, 0) - transcriptionAudioOffsetSeconds;

            return (
                <Div styles={styles.highlight} key={id} ref={pageId === eventItemId ? selectedRef : undefined}>
                    {userId === creatorId && (
                        <EventActionMenu
                            bookmarkId={id}
                            clickOpen
                            eventItemId={eventItemId}
                            highlight={highlight}
                            highlightsFilterKey={filterKey}
                            highlightsSortKey={sortKey}
                            styles={styles.actionMenu}
                        />
                    )}
                    <Div styles={highlightStyles}>
                        {speakerName && (
                            <Text size={3} weight="medium" styles={styles.highlightSpeaker}>
                                {speakerName}
                                {(speakerTitle || speakerAffiliation) && <br />}
                                {speakerTitle && (
                                    <Text span size={1}>
                                        {speakerTitle}
                                    </Text>
                                )}
                                {speakerAffiliation && (
                                    <Text span size={1}>
                                        {`, ${speakerAffiliation}`}
                                    </Text>
                                )}
                            </Text>
                        )}
                        <Text
                            size={1}
                            styles={{ ...styles.highlightTime, color: getHighlightColor(highlightColor)?.dark02 }}
                        >
                            {toDateTimeString(
                                startTimestamp,
                                false,
                                false,
                                hasUnknownTime ? 'MMM dS, yyyy' : 'h:mm:ssTT MMM dS, yyyy'
                            )}
                        </Text>
                        <Div
                            styles={styles.highlightText}
                            onClick={() => {
                                onNodeSelect({ id: eventItemId, withScroll: true });
                            }}
                        >
                            <RawHTML className="highlightText" html={highlight} />
                        </Div>
                        {hasTime && mediaPlayer.canListen && (
                            <Div
                                styles={styles.listenButton}
                                onClick={() => {
                                    onNodeSelect({ id: eventItemId, seekToAudio: true });
                                    if (!mediaPlayer.listening && mediaPlayer.canListen) {
                                        mediaPlayer.listen();
                                    }
                                }}
                            >
                                <Icon type="play" color={theme.colors.white01} />
                                <Text size={1}>Listen from {toDurationString(startTime)}</Text>
                            </Div>
                        )}
                    </Div>
                    <Div styles={styles.note}>
                        <Div styles={styles.noteHeader}>
                            <Text size={3} weight="medium">
                                {creatorName}
                            </Text>
                            <Text size={1} styles={styles.noteCreated}>
                                {new XDate(created).toString('h:mm TT MMM dS, yyyy')}
                            </Text>
                            {userId === creatorId && (
                                <WithPermission permissions={[PERMISSIONS.sharingBookmarks]}>
                                    <Hint
                                        text={shared ? 'Visible to your team' : 'Hidden from your team'}
                                        styles={styles.sharedIndicator}
                                        growLeft
                                        fromLeft
                                        yOffset={-19}
                                        xOffset={-6}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setVisibility({ bookmarkId: id, shared: !shared });
                                        }}
                                    >
                                        <Icon type={shared ? 'eye' : 'eyeStrike'} color={theme.colors.gray04} />
                                    </Hint>
                                </WithPermission>
                            )}
                        </Div>
                        {note && (
                            <Text size={3} styles={styles.noteText}>
                                {note}
                            </Text>
                        )}
                        {tags && tags.length > 0 && (
                            <Div styles={styles.tags}>
                                {tags.map(tag => (
                                    <Div
                                        styles={{
                                            ...styles.tag,
                                            border: `1px solid ${getHighlightColor(highlightColor)?.dark}`,
                                            color: getHighlightColor(highlightColor)?.dark02,
                                            backgroundColor: getHighlightColor(highlightColor)?.color
                                        }}
                                        key={tag}
                                    >
                                        <Text size={0}>{tag}</Text>
                                    </Div>
                                ))}
                            </Div>
                        )}
                    </Div>
                </Div>
            );
        });
    }

    renderEmptyMessage() {
        const { hasHighlights, openVideoModal, styles, theme, videoPlayerRef } = this.props;
        return (
            <Div styles={styles.emptyMessage}>
                <Icon style={styles.emptyIcon} styles={styles.emptyIcon} type="highlight" color={theme.colors.gray04} />
                <Text size={3}>No matching highlights for this event.</Text>
                <Text size={1}>Highlight text to create a highlight.</Text>
                <Graphic styles={styles.emptyGraphic} type="backdrop" color={theme.colors.gray04} opacity={0.1} />
                {!hasHighlights && (
                    <Fragment>
                        <Text size={1} styles={styles.videoDescription}>
                            Click the video below to learn about
                            <br />
                            Aiera&apos;s Highlights feature
                        </Text>
                        <VideoPlayer
                            onClickPreview={openVideoModal}
                            playerRef={videoPlayerRef}
                            showControls={false}
                            showPreview
                            styles={styles.videoPlayer}
                            videoType={VIDEO_TYPES.workspaceHighlights}
                        />
                    </Fragment>
                )}
            </Div>
        );
    }

    render() {
        const { matches, passedStyles, styles, loading } = this.props;

        if (loading) {
            return (
                <Div styles={styles.loader}>
                    <LoaderLogo height={60} />
                </Div>
            );
        }

        return (
            <Div styles={{ ...styles.container, ...passedStyles }}>
                {this.renderHeader()}
                <Div styles={styles.scrollContainer}>
                    {matches && matches.length > 0 ? this.renderHighlights() : this.renderEmptyMessage()}
                </Div>
            </Div>
        );
    }
}

export const EventHighlightsUI = compose(withStyleSheet(styleSheet))(EventHighlights);
