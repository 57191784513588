import { compose } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'graphql/utils';

export const withData = () =>
    compose(
        graphql(
            gql`
                query withRecommendedEvents {
                    recommendedEvents {
                        id
                        title
                        callDate
                        equity {
                            id
                            commonName
                            localTicker
                            exchange {
                                id
                                shortName
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => {
                    return {
                        recommendedEvents: data.recommendedEvents,
                        fetchMore: data.fetchMore,
                        loading: data.loading,
                        refetch: data.refetch
                    };
                },
                options: {
                    fetchPolicy: 'cache-first',
                    returnPreviousData: false
                }
            }
        )
    );
