import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { statusBannerFire } from 'actions/statusBanner';
import { graphql } from 'graphql/utils';

export const withCreateTranscrippet = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation CreateTranscrippet(
                    $companyId: ID
                    $equityId: ID
                    $eventId: ID!
                    $transcript: String!
                    $transcriptEndItemId: ID
                    $transcriptEndItemOffset: Int
                    $transcriptItemId: ID!
                    $transcriptItemOffset: Int
                ) {
                    createTranscrippet(
                        input: {
                            companyId: $companyId
                            equityId: $equityId
                            eventId: $eventId
                            transcript: $transcript
                            transcriptEndItemId: $transcriptEndItemId
                            transcriptEndItemOffset: $transcriptEndItemOffset
                            transcriptItemId: $transcriptItemId
                            transcriptItemOffset: $transcriptItemOffset
                        }
                    ) {
                        transcrippet {
                            audioUrl
                            companyId
                            companyLogoUrl
                            companyName
                            companyTicker
                            endMs
                            equityId
                            eventDate
                            eventId
                            eventType
                            id
                            speakerId
                            speakerName
                            speakerTitle
                            startMs
                            status
                            transcrippetGuid
                            transcript
                            transcriptionAudioOffsetSeconds
                            transcript
                            wordDurationsMs
                        }
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    createTranscrippet: ({
                        companyId,
                        equityId,
                        eventId,
                        transcript,
                        transcriptItemId,
                        transcriptItemOffset,
                        transcriptEndItemId,
                        transcriptEndItemOffset
                    }) => {
                        return mutate({
                            variables: {
                                companyId,
                                equityId,
                                eventId,
                                transcript,
                                transcriptItemId,
                                transcriptItemOffset,
                                transcriptEndItemId,
                                transcriptEndItemOffset
                            }
                        }).catch(error => {
                            setStatusBanner(`Error sharing snippet: ${error}`, 'error', 'circleX');
                            throw error;
                        });
                    }
                })
            }
        )
    );
