import gql from 'graphql-tag';

export const ruleFragment = gql`
    fragment rule on BaseStreamRule {
        ruleType
        condition
        value
        ... on CountryCodeStreamRule {
            country {
                id
                shortName
            }
        }
        ... on GicsSectorIdStreamRule {
            sector: gicsSector {
                id
                name
            }
        }
        ... on GicsSubSectorIdStreamRule {
            sector: gicsSubSector {
                id
                name
            }
        }
        ... on EquityIdStreamRule {
            equity {
                id
                localTicker
                exchange {
                    id
                    shortName
                }
            }
        }
        ... on WatchlistStreamRule {
            watchlist {
                id
                name
            }
        }
        ... on NewsSourceTagStreamRule {
            newsSourceTag {
                id
                displayName
            }
        }
        ... on NewsSourceStreamRule {
            newsSource {
                id
                name
            }
        }
        ... on PersonIdStreamRule {
            person {
                id
                name
            }
        }
        ... on EventGroupIdStreamRule {
            eventGroup {
                id
                title
            }
        }
        ... on SpotlightTypeStreamRule {
            spotlightTypeHierarchyItem {
                id
                displayName
            }
        }
        ... on FilingFormStreamRule {
            filingForm {
                id
                formNumber
                formNameShort
            }
        }
        ... on IndexIdStreamRule {
            index {
                id
                displayName
            }
        }
    }
`;

export const companyMatchFragment = gql`
    fragment companyMatch on CompanyStreamMatch {
        id
        highlights
        company {
            id
            commonName
            iconUrl
            instruments {
                id
                isPrimary
                quotes {
                    id
                    currency {
                        id
                        currencyCode
                        minorSymbol
                        minorSymbolPrefix
                        symbol
                        symbolPrefix
                    }
                    exchange {
                        id
                        country {
                            id
                            countryCode
                        }
                        shortName
                    }
                    isPrimary
                    localTicker
                    latestOhlc {
                        close: adjClose
                        date
                        high: adjHigh
                        low: adjLow
                        open: adjOpen
                        volume: adjVolume
                    }
                    prevClose
                    technicals {
                        id
                        averageDailyVolume
                        pricetoearnings
                        volume
                    }
                }
            }
            statistics {
                newToday {
                    buckets {
                        count
                        type
                    }
                    total
                }
            }
        }
    }
`;

export const dashboardSectionFragment = gql`
    fragment dashboardSection on DashboardSection {
        id
        sectionId
        name
    }
`;

export const dashboardFragment = gql`
    fragment dashboard on Dashboard {
        id
        creatingUser {
            id
            firstName
            lastName
            username
        }
        equity {
            id
            commonName
        }
        capabilities
        recommended
        creatingUserId
        dashboardGuid
        dashboardType
        description
        lastViewed
        modified
        name
        numLiveEvents
        ownedByCurrentUser
        searchable
        starred
        tags
        uxPreferences
    }
`;

export const dashboardEquityFragment = gql`
    fragment dashboardEquity on Equity {
        id
        equityId
        commonName
        currency {
            id
            currencyCode
            symbol
            symbolPrefix
            minorSymbol
            minorSymbolPrefix
        }
        exchange {
            id
            priceDelay
        }
        last
        lastClose
        localTicker
    }
`;

export const streamFragment = gql`
    fragment stream on Stream {
        id
        averageDailyVolume
        clonedFrom
        filterMode
        modified
        name
        streamId
        streamKey
        streamType
        uxPreferences
        ... on CustomDataStream {
            dataCollectionId
            streamMatchTemplateId
        }
    }
`;

export const dashboardMatchFragment = gql`
    fragment dashboardMatch on DashboardStreamMatch {
        id
        dashboard {
            id
            dashboardId
            equityId
            name
            dashboardType
            recommended
            ownedByCurrentUser
            numLiveEvents
            streams {
                id
                name
            }
        }
    }
`;

export const equityMatchFragment = gql`
    fragment equityMatch on EquityStreamMatch {
        id
        highlights
        equity {
            id
            icon
            commonName
            currency {
                id
                currencyCode
                symbol
                symbolPrefix
                minorSymbol
                minorSymbolPrefix
            }
            last
            lastClose
            localTicker
            nextEarnings: nextEarningsDate
            marketcap
            valuation {
                metric
                nextTwelveMonths
            }
            sector: gicsSector {
                id
                name
            }
            exchange {
                id
                shortName
            }
        }
    }
`;

export const eventMatchFragment = gql`
    fragment eventMatch on EventStreamMatch {
        id
        highlights
        event {
            id
            noLiveAccess
            audioStreamUri
            authToken
            callDate
            callProvider
            callType
            conferenceNumber
            conferencePin
            createdByUser {
                id
                username
                firstName
                lastName
                organization {
                    id
                    name
                }
            }
            deleted
            emailNotificationsEnabled
            equity {
                id
                icon
                commonName
                localTicker
                exchange {
                    id
                    shortName
                }
                currency {
                    id
                    currencyCode
                    symbol
                    symbolPrefix
                    minorSymbol
                    minorSymbolPrefix
                }
            }
            eventGroups {
                id
                promoted
                title
            }
            eventId
            externalAudioStreamUrl
            hasTonalSentiment
            hasTranscript
            hasUnknownTime
            isLive
            priceHighlight {
                endOrLatestPrice
                movementAbsolute
                movementPercent
                overThreshold
            }
            scheduledAudioCallId
            siftTokens
            summarizations {
                audioClip
                videoClip
                id
                modelType
                summary
                summaryType
                title
            }
            title
            subTitle
            transcriptionAudioOffsetSeconds
            transcriptionAudioUrl
            transcriptionStatus
            webcastStatus
        }
        userSettings {
            id
            score
        }
    }
`;

export const askAieraMatchFragment = gql`
    fragment eventMatch on EventStreamMatch {
        id
        highlights
        event {
            id
            askedQuestions {
                id
            }
            callDate
            callType
            scheduledAudioCallId
            title
        }
    }
`;

export const notificationMatchFragment = gql`
    fragment notificationMatch on NotificationStreamMatch {
        id
        notification {
            id
            content {
                id
                equities {
                    id
                    localTicker
                    commonName
                }
                ... on NewsContent {
                    id
                    newsSource {
                        id
                        name
                    }
                }
                ... on ResearchContent {
                    body
                    summary
                }
                title
            }
            created
            deleted
            dashboard {
                id
                equityId
                name
            }
            equity {
                id
                commonName
                currency {
                    id
                    symbol
                    symbolPrefix
                    minorSymbol
                    minorSymbolPrefix
                }
            }
            event {
                id
                callDate
                callType
                title
            }
            transcriptId
            isRead
            message {
                ... on ContentNotificationMessage {
                    numMentions
                    numMentionsChangePercent
                    period
                }
                ... on EventPriceReactionNotificationMessage {
                    endOrLatestPrice
                    movementAbsolute
                    movementPercent
                }
                ... on EventQuicklinksMatchNotificationMessage {
                    highlight
                }
                ... on SpotlightMatchNotificationMessage {
                    highlight
                    keywordMatches
                }
                ... on StreamMatchNotificationMessage {
                    highlight
                    keywordMatches
                }
                ... on StreamMatchRollupNotificationMessage {
                    equities {
                        id
                        localTicker
                    }
                    keywords {
                        keyword
                        count
                    }
                    numMentions
                    since
                }
                ... on StreamSpikeNotificationMessage {
                    highlights
                    numMentions
                    numMentionsChangePercent
                    period
                }
            }
            notificationType
            stream {
                id
                name
            }
        }
    }
`;

export const transcriptMatchFragment = gql`
    fragment transcriptMatch on TranscriptStreamMatch {
        id
        highlights
        highlightFields
        itemId
        bookmark {
            id
            highlightColor
            status
        }
        transcript {
            id
            itemId
            startTimestamp
            startMs
            sentiment {
                primarySentiment
            }
            speaker {
                id
                name
                affiliation
                title
            }
            event {
                eventId
                callType
                callDate
                pressUrl
                slidesUrl
                hasPublishedTranscript
                equity {
                    id
                    icon
                    localTicker
                    commonName
                    exchange {
                        id
                        shortName
                    }
                }
                tags {
                    tag
                    users {
                        id
                        username
                    }
                }
                id
                title
                subTitle
                userSettings {
                    id
                    archived
                    starred
                    isRead
                    tags
                }
            }
        }
        collapsed {
            id
            highlights
            highlightFields
            itemId
            transcript {
                id
                itemId
                startTimestamp
                startMs
                speaker {
                    id
                    name
                    affiliation
                    title
                }
            }
        }
        userSettings {
            id
            score
        }
    }
`;

export const transcriptMatchNoCollapsedFragment = gql`
    fragment transcriptMatch on TranscriptStreamMatch {
        id
        highlights
        highlightFields
        itemId
        bookmark {
            id
            highlightColor
            status
        }
        transcript {
            id
            itemId
            startTimestamp
            startMs
            sentiment {
                primarySentiment
            }
            speaker {
                id
                name
                affiliation
                title
            }
            event {
                eventId
                callDate
                pressUrl
                slidesUrl
                hasPublishedTranscript
                equity {
                    id
                    icon
                    localTicker
                    commonName
                    exchange {
                        id
                        shortName
                    }
                }
                tags {
                    tag
                    users {
                        id
                        username
                    }
                }
                id
                title
                userSettings {
                    id
                    archived
                    starred
                    isRead
                    tags
                }
            }
        }
        userSettings {
            id
            score
        }
    }
`;

export const contentMatchFragment = gql`
    fragment contentMatch on ContentStreamMatch {
        id
        highlights
        highlightsMatches
        highlightTitle
        bookmark {
            id
            highlightColor
            status
        }
        content {
            id
            contentType
            displayType
            publishedDate
            source
            title
            primaryEquity {
                id
                localTicker
                commonName
                icon
                exchange {
                    id
                    shortName
                }
            }
            equities {
                id
                localTicker
                commonName
                icon
                exchange {
                    id
                    shortName
                }
            }
            categories {
                categoryId
                shortName
                displayName
                id
            }
            events {
                id
                callDate
                title
            }
            ... on CorporateActionContent {
                id
                actionSubtype
                actionType
                corporateActionEventId: eventId
            }
            ... on NewsContent {
                url
                newsSource {
                    id
                    name
                }
            }
            ... on ResearchContent {
                summary
                numPages
                publishedDate
                synopsis
                docType
                docCategory
                docFocus
                disciplineType
                disciplineApproach
                assetClasses
                assetTypes
                authors {
                    id
                    name
                }
                publishers {
                    id
                    source
                }
            }
            ... on DocumentContent {
                uploadingUserId
                documentFormat
                numPages
                originalFileName
                createdDate
                user {
                    id
                    email
                }
            }
            ... on AttachmentContent {
                attachmentType
                eventId
            }
            ... on FilingContent {
                pdfUrl
                filing {
                    id
                    form {
                        id
                        formName
                        formNameShort
                        formNumber
                    }
                    periodEndDate
                    releaseDate
                    arrivalDate
                    isAmendment
                    officialUrl
                }
            }
            ... on GuidanceSpotlightContent {
                guidanceTrend
                eventDate
            }
            ... on PartnershipSpotlightContent {
                eventDate
            }
            ... on AssetPurchaseSpotlightContent {
                eventDate
            }
            ... on BuybackSpotlightContent {
                eventDate
            }
            ... on SalesMetricSpotlightContent {
                eventDate
            }
            ... on MAndASpotlightContent {
                eventDate
            }
            ... on SpinOffSpotlightContent {
                eventDate
            }
            ... on IPOSpotlightContent {
                eventDate
            }
            userSettings {
                id
                archived
                starred
                isRead
                tags
            }
            tags {
                tag
                users {
                    id
                    username
                }
            }
        }
        collapsed {
            id
            highlights
            content {
                id
                contentType
                title
                publishedDate
                primaryEquity {
                    id
                    localTicker
                    commonName
                    icon
                }
                equities {
                    id
                    localTicker
                    commonName
                    icon
                }
                ... on NewsContent {
                    url
                    newsSource {
                        id
                        name
                    }
                }
                ... on DocumentContent {
                    uploadingUserId
                    documentFormat
                    numPages
                    originalFileName
                    createdDate
                    user {
                        id
                        email
                    }
                }
                ... on AttachmentContent {
                    attachmentType
                    eventId
                }
                ... on CorporateActionContent {
                    id
                    actionSubtype
                    actionType
                    corporateActionEventId: eventId
                }
                ... on ResearchContent {
                    summary
                    numPages
                    publishedDate
                    synopsis
                    docType
                    docCategory
                    docFocus
                    disciplineType
                    disciplineApproach
                    assetClasses
                    assetTypes
                    authors {
                        id
                        name
                    }
                    publishers {
                        id
                        source
                    }
                }
            }
        }
        userSettings {
            id
            score
        }
    }
`;

export const eventGroupMatchFragment = gql`
    fragment eventGroupMatch on EventGroupStreamMatch {
        id
        highlights
        eventGroup {
            id
            end
            numEvents
            promoted
            start
            title
            ... on Conference {
                hostEquity {
                    id
                    localTicker
                    commonName
                    exchange {
                        id
                        shortName
                    }
                }
            }
        }
    }
`;

export const gSheetMatchFragment = gql`
    fragment gSheetMatch on GSheetStreamMatch {
        id
        highlights
        fields {
            name
            value
        }
    }
`;

export const streamRollupMatchFragment = gql`
    fragment streamRollupMatch on StreamRollupStreamMatch {
        id
        total
        stream {
            id
            name
            dashboards {
                id
                name
            }
        }
    }
`;

export const customDataMatchFragment = gql`
    fragment customDataMatch on CustomDataStreamMatch {
        id
        highlights
        record {
            id
            dataRecordId
            equityId
            equity {
                id
                equityId
                localTicker
                commonName
                exchange {
                    id
                    shortName
                }
            }
            recordType
            recordId
            recordDate
            recordTicker
            record {
                formatted
                name
                value
            }
        }
        collapsed {
            id
            highlights
            ... on CustomDataStreamMatch {
                record {
                    id
                    dataRecordId
                    equityId
                    equity {
                        id
                        equityId
                        localTicker
                        commonName
                        exchange {
                            id
                            shortName
                        }
                    }
                    recordType
                    recordId
                    recordDate
                    recordTicker
                    record {
                        formatted
                        name
                        value
                    }
                }
            }
        }
    }
`;

export const dataCollectionFragment = gql`
    fragment dataCollection on DataCollection {
        id
        dataCollectionId
        collectionType
        name
        configuration {
            ... on GSheetConfiguration {
                url
            }
            ... on CsvConfiguration {
                url
            }
            ... on ApiConfiguration {
                url
            }
        }
        processingStatus
        processingStatusMessage
    }
`;

export const dataCollectionRecordFragment = gql`
    fragment dataCollectionRecord on DataCollectionRecord {
        id
        dataRecordId
        equityId
        equity {
            id
            equityId
            localTicker
            commonName
            exchange {
                id
                shortName
            }
        }
        recordType
        recordId
        recordDate
        recordTicker
        record {
            formatted
            name
            value
        }
    }
`;

export const streamMatchTemplateFragment = gql`
    fragment streamMatchTemplate on StreamMatchTemplate {
        id
        templateId
        name
        templateType
        configuration {
            ... on BasicTemplateConfiguration {
                date
                ticker
                url
                title
                subtitle
                topRight
                bottomRight
                body
                bodyRaw
                fullPageBody
                fullPageBodyCss
            }
        }
    }
`;
