import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PREFERENCES } from 'consts';
import { withReporting } from 'provider/reporting';
import { withUrlContext } from 'hoc/url';
import { withUserPreferences } from 'graphql/user';
import { generateTabURL, generateModalId, get, hasPreference } from 'utils';
import { withData } from './data';
import { TranscriptStreamUI } from './ui';

export class TranscriptStream extends PureComponent {
    static displayName = 'TranscriptStreamContainer';

    static propTypes = {
        dashboardId: PropTypes.string.isRequired,
        dashSearchTerm: PropTypes.string,
        eventId: PropTypes.string,
        eventDate: PropTypes.string,
        eventTitle: PropTypes.string,
        eventType: PropTypes.string,
        equityId: PropTypes.string,
        expectPublishedTranscript: PropTypes.bool,
        hasDetails: PropTypes.bool,
        hasPublishedTranscript: PropTypes.bool,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isLive: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        preferences: PropTypes.objectOf(PropTypes.any),
        hasPartial: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        subtitle: PropTypes.string,
        trackEventActivity: PropTypes.func.isRequired,
        transcriptionStatus: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        dashSearchTerm: null,
        eventId: null,
        eventDate: null,
        eventTitle: null,
        eventType: null,
        equityId: null,
        expectPublishedTranscript: false,
        hasDetails: false,
        hasPublishedTranscript: false,
        isLive: false,
        loading: false,
        loadMoreMatches: null,
        matches: [],
        mediaPlayer: null,
        preferences: {},
        hasPartial: false,
        streamId: null,
        styles: undefined,
        subtitle: undefined,
        transcriptionStatus: undefined,
        user: undefined
    };

    constructor(props) {
        super(props);

        this.chartRef = createRef();
        this.togglePlayEvent = this.togglePlayEvent.bind(this);
        this.openUpgradeModal = this.openUpgradeModal.bind(this);
        this.openSubmitDetails = this.openSubmitDetails.bind(this);
    }

    componentDidMount() {
        const { trackEventActivity, eventId } = this.props;
        if (eventId) {
            trackEventActivity(eventId);
        }
    }

    componentDidUpdate({ eventId: prevEventId, streamProps: prevStreamProps }) {
        const { eventId, streamProps, trackEventActivity } = this.props;

        if (eventId !== prevEventId) {
            trackEventActivity(eventId);
        }

        const prevWidth = get(prevStreamProps, 'width');
        const width = get(streamProps, 'width');
        if (width !== prevWidth && this.chartRef.current) {
            this.chartRef.current.chart.update({
                chart: {
                    width
                }
            });
        }
    }

    togglePlayEvent() {
        const { eventId, mediaPlayer, reporter } = this.props;
        if (mediaPlayer.listening) {
            if (mediaPlayer.seekable) {
                reporter.track(reporter.actions.click, reporter.objects.audioPause, {
                    live: !!get(mediaPlayer, 'isLive'),
                    component: 'TranscriptStream',
                    eventId
                });
                mediaPlayer.pause();
            } else {
                reporter.track(reporter.actions.click, reporter.objects.audioStop, {
                    live: !!get(mediaPlayer, 'isLive'),
                    component: 'TranscriptStream',
                    eventId
                });
                mediaPlayer.stop();
            }
        } else if (mediaPlayer.canListen) {
            reporter.track(reporter.actions.click, reporter.objects.audioPlay, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'TranscriptStream',
                eventId
            });
            mediaPlayer.listen();
        }
    }

    openUpgradeModal() {
        const { history, pathname } = this.props;
        history.push(generateModalId({ pathname, id: 'liveEvents', type: 'upgrade' }));
    }

    openSubmitDetails() {
        const { eventId, history } = this.props;
        history.push(generateModalId({ location: window.location, id: eventId, type: 'submitEventDetails' }));
    }

    render() {
        const {
            dashSearchTerm,
            dashboardId,
            equityId,
            eventDate,
            eventId,
            eventTitle,
            eventType,
            expectPublishedTranscript,
            hasDetails,
            hasPartial,
            hasPublishedTranscript,
            isLive,
            loadMoreMatches,
            loading,
            matches,
            mediaPlayer,
            pathname,
            preferences,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle,
            transcriptionStatus,
            user
        } = this.props;
        return (
            <TranscriptStreamUI
                bottomUp={hasPreference(preferences, { ...PREFERENCES.reverseLiveTranscripts, value: true }, true)}
                chartRef={this.chartRef}
                dashSearchTerm={dashSearchTerm}
                dashboardId={dashboardId}
                equityId={equityId}
                eventDate={eventDate}
                eventHref={generateTabURL({ pathname, eventId })}
                eventId={eventId}
                eventTitle={eventTitle}
                eventType={eventType}
                expectPublishedTranscript={expectPublishedTranscript}
                hasDetails={hasDetails}
                hasPartial={hasPartial}
                hasPublishedTranscript={hasPublishedTranscript}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                matches={matches}
                mediaPlayer={isLive ? mediaPlayer : null}
                openSubmitDetails={this.openSubmitDetails}
                openUpgradeModal={this.openUpgradeModal}
                streamDisplayMode={streamDisplayMode}
                streamId={streamId}
                streamProps={streamProps}
                styles={styles}
                subtitle={subtitle}
                togglePlayEvent={this.togglePlayEvent}
                transcriptionStatus={transcriptionStatus}
                user={user}
            />
        );
    }
}

export const TranscriptStreamContainer = compose(
    withUrlContext(['history', 'pathname']),
    withData(),
    withReporting(),
    withUserPreferences({ fetchPolicy: 'cache-first', variables: { withDetails: true } })
)(TranscriptStream);
