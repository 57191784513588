import gql from 'graphql-tag';

export const bookmarkFragment = gql`
    fragment bookmark on Bookmark {
        id
        bookmarkId
        bookmarkUrlType
        created
        equityIds
        highlight
        highlightColor
        modified
        note
        reminder
        shared
        status
        tags
        targetEquityId
        targetId
        targetStreamId
        targetType
        user {
            id
            email
            firstName
            lastName
        }
    }
`;

export const bookmarkTargetFragment = gql`
    fragment bookmarkTarget on Bookmark {
        target {
            __typename
            ... on Content {
                id
                contentType
                primaryEquity {
                    id
                    localTicker
                    commonName
                    icon
                    exchange {
                        id
                        shortName
                    }
                }
                publishedDate
                title
            }
            ... on FilingContent {
                filing {
                    id
                    form {
                        id
                        formName
                        formNameShort
                        formNumber
                    }
                    periodEndDate
                    releaseDate
                    arrivalDate
                    isAmendment
                    officialUrl
                }
            }
            ... on NewsContent {
                newsSource {
                    id
                    name
                }
            }
            ... on ScheduledAudioCall {
                id
                callDate
                equity {
                    id
                    localTicker
                    commonName
                    icon
                    exchange {
                        id
                        shortName
                    }
                }
                hasUnknownTime
                title
                userSettings {
                    id
                    shareBookmarks
                }
            }
            ... on ScheduledAudioCallEvent {
                id
                event {
                    id
                    callDate
                    equity {
                        id
                        localTicker
                        commonName
                        icon
                        exchange {
                            id
                            shortName
                        }
                    }
                    hasUnknownTime
                    title
                    transcriptionAudioOffsetSeconds
                    userSettings {
                        id
                        shareBookmarks
                    }
                }
                itemId
                startTimestamp
                startMs
                speaker {
                    id
                    speakerId
                    name
                    title
                    affiliation
                }
            }
        }
    }
`;
