import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { TimeAgo } from 'components/TimeAgo';
import { styleSheet } from './stylesheet';

class RecommendedEvent extends PureComponent {
    static propTypes = {
        callDate: PropTypes.string,
        hideTooltip: PropTypes.func.isRequired,
        exchange: PropTypes.string,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        passedStyles: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        ticker: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        callDate: undefined,
        exchange: undefined,
        mediaPlayer: null,
        passedStyles: {},
        ticker: undefined,
        title: undefined
    };

    render() {
        const { passedStyles, styles, mediaPlayer, theme, title, callDate, ticker, exchange, hideTooltip } = this.props;
        return (
            <Div
                styles={{ ...styles.container, ...passedStyles }}
                onClick={e => {
                    if (mediaPlayer.canListen) {
                        mediaPlayer.listen();
                    } else {
                        mediaPlayer.play();
                    }
                    hideTooltip(e);
                }}
            >
                <Div styles={styles.playBtn}>
                    <Icon type="play" color={theme.colors.white01} />
                </Div>
                <Div styles={styles.trackInfo}>
                    {title && (
                        <Text size={1} className="trackTitle">
                            {title}
                        </Text>
                    )}
                    <Div styles={styles.trackDetails}>
                        {ticker && <Text className="ticker">{ticker}</Text>}
                        <Text styles={styles.exchange} size={0} uppercase>
                            {exchange}
                        </Text>
                        <Text size={0} styles={styles.trackDate} uppercase>
                            <TimeAgo date={callDate} />
                        </Text>
                    </Div>
                </Div>
            </Div>
        );
    }
}

export const RecommendedEventUI = compose(withStyleSheet(styleSheet))(RecommendedEvent);
