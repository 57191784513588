import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { get } from 'utils';
import { withEventMediaPlayer } from 'graphql/audioCalls';
import { withReporting } from 'provider/reporting';
import { EventMobilePlayerUI } from './ui';

export class EventMobilePlayer extends PureComponent {
    static displayName = 'EventMobilePlayerContainer';

    static propTypes = {
        eventId: PropTypes.string,
        callDate: PropTypes.string,
        callType: PropTypes.string,
        equity: PropTypes.objectOf(PropTypes.any),
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        eventId: undefined,
        callDate: null,
        callType: null,
        equity: null
    };

    constructor(props) {
        super(props);

        this.togglePlayEvent = this.togglePlayEvent.bind(this);
    }

    togglePlayEvent(event) {
        const { eventId, mediaPlayer, reporter } = this.props;
        event.preventDefault();
        event.stopPropagation();

        if (mediaPlayer.listening) {
            if (mediaPlayer.seekable) {
                reporter.track(reporter.actions.click, reporter.objects.audioPause, {
                    live: !!get(mediaPlayer, 'isLive'),
                    component: 'EventCard',
                    eventId
                });
                mediaPlayer.pause();
            } else {
                reporter.track(reporter.actions.click, reporter.objects.audioStop, {
                    live: !!get(mediaPlayer, 'isLive'),
                    component: 'EventCard',
                    eventId
                });
                mediaPlayer.stop();
            }
        } else if (mediaPlayer.canListen) {
            reporter.track(reporter.actions.click, reporter.objects.audioPlay, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'EventCard',
                eventId
            });
            mediaPlayer.listen();
        }
    }

    render() {
        const { mediaPlayer, callDate, callType, equity } = this.props;
        const canListen = get(mediaPlayer, 'canListen', false);

        return (
            <EventMobilePlayerUI
                togglePlayEvent={this.togglePlayEvent}
                callDate={callDate}
                callType={callType}
                equity={equity}
                canListen={canListen}
                mediaPlayer={mediaPlayer}
            />
        );
    }
}

export const EventMobilePlayerContainer = compose(
    withEventMediaPlayer({ allowLiveStream: true, loadAudio: true }),
    withReporting()
)(EventMobilePlayer);
