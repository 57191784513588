/* eslint-disable no-nested-ternary */

import EventEmitter from 'events';
import autoBind from 'auto-bind';
import XDate from 'xdate';
import { config } from 'configuration';
import { AudioPlayer } from 'utils/media/audio';
import { DTMF } from 'utils/media/dtmf';
import { WebRTC } from 'utils/media/gridspace';
import { TwilioWebRTC } from 'utils/media/twilio';
import { mobileBridge } from 'provider/mobileBridge';

/* Core state management for media players.
 *
 * The static functions/properties are there to keep
 * a global list of all registered media players by their
 * id. If two components need players for the same id, we
 * give them the same instance so that the player state is
 * automatically synced across them.
 *
 * Each component increments the player reference count
 * when retrieving a player, and decrements when done
 * (on unmount). When the reference count goes to 0,
 * we delete the player.
 *
 * All events from the currently active player get forwarded
 * to the "activeEvents" property. If you just want to track
 * the active player, register listeners on those events, and
 * then reference MediaPlayer.activePlayer.
 */
export class MediaPlayer extends EventEmitter {
    static webrtc = new WebRTC();

    static twilio = new TwilioWebRTC();

    static players = {};

    static activePlayer = null;

    static activeEvents = new EventEmitter();

    /* If player with the id exists,
     * increment ref count and return it.
     * Otherwise create it and set ref to 1.
     */
    static getPlayer(id) {
        let player = this.players[id];
        if (!player) {
            player = new MediaPlayer(id);
            this.players[id] = player;
            player.addListeners();
        }
        player.ref += 1;
        return player;
    }

    /* Decrement ref count. If there are no
     * more references to the player, unregister
     * the listeners and delete it from the global
     * map.
     */
    static cleanupPlayer(id) {
        const player = this.players[id];
        if (!player) return;

        player.ref -= 1;
        if (!player.ref) {
            player.removeListeners();
            delete this.players[id];
        }
    }

    /* Call reset on all players. Done when switching to a new
     * active player.
     */
    static resetAll() {
        Object.values(this.players).forEach(player => player.reset(false));
    }

    /* Set a player as active. Cleanup the existing activePlayer
     * and set the new one. Also reset all players, since the active
     * one is the only one that can be playingrecording.
     */
    static setActive(player) {
        if (player !== this.activePlayer) {
            this.resetAll();
            if (this.activePlayer) {
                this.activePlayer.reset();
                this.cleanupPlayer(this.activePlayer.id);
            }
            this.activePlayer = player ? this.getPlayer(player.id) : null;
            this.activeEvents.emit('activeChange');
        }
    }

    constructor(id) {
        super();
        autoBind(this);
        this.id = id;
        this.ref = 0;
        // There's only one of these, but set this to make it easier to reference
        this.webrtc = MediaPlayer.webrtc;
        this.twilio = MediaPlayer.twilio;
        this.audio = new AudioPlayer();
        this.dtmf = new DTMF();
        this.mediaStartTime = new XDate();
    }

    // Forward events from the audio and webrtc instances to this so that
    // users can just listen for events on this instance.
    addListeners() {
        this.audio.on('timeChange', this.onTimeChange);
        this.audio.on('durationChange', this.onDurationChange);
        this.audio.on('statusChange', this.onStatusChange);
        this.audio.on('seek', this.onSeek);

        this.webrtc.on('timeChange', this.onTimeChange);
        this.webrtc.on('durationChange', this.onDurationChange);
        this.webrtc.on('connected', this.onConnected);
        this.webrtc.on('disconnected', this.onDisconnected);
        this.webrtc.on('muted', this.onStatusChange);
        this.webrtc.on('decibelChange', this.onDecibelChange);
        this.webrtc.on('error', this.onError);

        this.twilio.on('timeChange', this.onTimeChange);
        this.twilio.on('durationChange', this.onDurationChange);
        this.twilio.on('connected', this.onConnected);
        this.twilio.on('disconnected', this.onDisconnected);
        this.twilio.on('muted', this.onStatusChange);
        this.twilio.on('decibelChange', this.onDecibelChange);
        this.twilio.on('error', this.onError);
    }

    removeListeners() {
        this.audio.off('timeChange', this.onTimeChange);
        this.audio.off('durationChange', this.onDurationChange);
        this.audio.off('statusChange', this.onStatusChange);
        this.audio.off('seek', this.onSeek);

        this.webrtc.off('timeChange', this.onTimeChange);
        this.webrtc.off('durationChange', this.onDurationChange);
        this.webrtc.off('connected', this.onStatusChange);
        this.webrtc.off('disconnected', this.onStatusChange);
        this.webrtc.off('muted', this.onStatusChange);
        this.webrtc.off('decibelChange', this.onDecibelChange);

        this.twilio.off('timeChange', this.onTimeChange);
        this.twilio.off('durationChange', this.onDurationChange);
        this.twilio.off('connected', this.onStatusChange);
        this.twilio.off('disconnected', this.onStatusChange);
        this.twilio.off('muted', this.onStatusChange);
        this.twilio.off('decibelChange', this.onDecibelChange);

        clearInterval(this.interval);
    }

    // If this is the active player, forward the event
    // to "activeEvents" to, so anyone tracking the active
    // player will get an update.
    emit(...args) {
        super.emit(...args);
        if (this.isActive()) {
            MediaPlayer.activeEvents.emit(...args);
        }
    }

    /* Event handlers/forwarding */
    onTimeChange() {
        this.emit('timeChange');
    }

    onSeek(timeInfo) {
        this.emit('seek', timeInfo);
    }

    onDurationChange() {
        this.emit('durationChange');
    }

    onConnected() {
        this.loading = false;
        this.emit('connected');
        this.onStatusChange();
    }

    onDisconnected() {
        this.emit('disconnected');
        this.onStatusChange();
    }

    onStatusChange() {
        this.emit('timeChange');
        this.emit('statusChange');
    }

    onDecibelChange() {
        this.emit('decibelChange');
    }

    onError(e) {
        this.emit('error', e);
    }

    isActive() {
        return MediaPlayer.activePlayer === this;
    }

    set startTime(startTime) {
        this.mediaStartTime = startTime ? new XDate(startTime) : new XDate();
    }

    get startTime() {
        return this.mediaStartTime || new XDate();
    }

    /* Current state properties */
    get status() {
        const { connection: webrtcConnection } = this.webrtc;
        const { connection: twilioConnection } = this.twilio;
        let status = 'stopped';

        if (this.isActive() && twilioConnection) {
            const { customParameters = {} } = twilioConnection;
            if (twilioConnection.isMuted()) {
                status = 'muted';
            } else if (['record', 'agent'].includes(customParameters.mode)) {
                status = 'recording';
            } else {
                status = 'listening';
            }
        } else if (this.isActive() && webrtcConnection) {
            if (this.webrtc.muted) {
                status = 'muted';
            } else if (webrtcConnection.mode === 'record') {
                status = 'recording';
            } else {
                status = 'listening';
            }
        } else if (this.isActive() && this.audio.playing) {
            status = this.audio.paused ? 'paused' : 'listening';
        }

        return status;
    }

    get dialed() {
        const { connection } = this.webrtc;
        return this.isActive() && connection ? !!connection.dial : false;
    }

    get currentTime() {
        const { connection: webrtcConnection } = this.webrtc;
        const { connection: twilioConnection } = this.twilio;
        return this.isActive() && (twilioConnection || webrtcConnection) ? this.duration : this.audio.currentTime || 0;
    }

    get duration() {
        return this.audio.duration || 0;
    }

    get hasLoadedAudio() {
        return this.audio.hasLoaded;
    }

    get playbackRate() {
        return this.audio.playbackRate;
    }

    get seekable() {
        return this.audio.hasLoaded;
    }

    get tones() {
        const { connection: webrtcConnection } = this.webrtc;
        const { connection: twilioConnection } = this.twilio;
        return this.isActive() && twilioConnection
            ? this.twilio.tones
            : this.isActive() && webrtcConnection
            ? webrtcConnection.tones
            : [];
    }

    get decibels() {
        const { connection: webrtcConnection } = this.webrtc;
        const { connection: twilioConnection } = this.twilio;
        return this.isActive() && twilioConnection
            ? this.twilio.decibels
            : this.isActive() && webrtcConnection
            ? this.webrtc.decibels
            : 0;
    }

    get metaData() {
        const { connection: webrtcConnection } = this.webrtc;
        const { connection: twilioConnection } = this.twilio;
        return this.isActive() && twilioConnection
            ? this.twilio.metaData
            : this.isActive() && webrtcConnection
            ? webrtcConnection.metaData
            : this.isActive()
            ? this.audio.metaData
            : null;
    }

    get state() {
        return {
            currentTime: this.currentTime,
            decibels: this.decibels,
            dialed: this.dialed,
            duration: this.duration,
            hasLoadedAudio: this.hasLoadedAudio,
            seekable: this.seekable,
            metaData: this.metaData,
            playbackRate: this.playbackRate,
            status: this.status,
            tones: this.tones
        };
    }

    /* MediaPlayer API */
    load(uri, offset, metaData, defaultOffset) {
        return this.audio.load(uri, offset, metaData, defaultOffset);
    }

    play(uri, offset, metaData, defaultOffset) {
        MediaPlayer.setActive(this);
        return this.audio.play(uri, offset, metaData, defaultOffset);
    }

    live() {
        this.audio.live();
    }

    seek(currentTime, raw = false, emit, tryToNormalize) {
        this.audio.seek(currentTime, raw, emit, tryToNormalize);
    }

    setPlaybackRate(rate) {
        this.audio.setPlaybackRate(rate);
    }

    setVolume(volume) {
        this.audio.setVolume(volume);
    }

    pause() {
        const { connection: twilioConnection } = this.twilio;
        const isTwilio = !!twilioConnection;

        if (this.isActive() && isTwilio) {
            this.twilio.resetDevice();
            return;
        }

        this.audio.pause();
    }

    stop() {
        MediaPlayer.setActive(null);
    }

    reset(single = true) {
        this.audio.reset();
        if (single) {
            this.webrtc.reset();
            this.webrtc.resetMobile();
            this.twilio.reset();
        }
    }

    authenticateGridspace(siftToken, mobile, pcConfig) {
        let onAuth;
        let onAuthError;
        return new Promise((resolve, reject) => {
            onAuth = resolve;
            onAuthError = reject;
            this.webrtc.once('authenticated', onAuth);
            this.webrtc.once('error', onAuthError);
            if (mobile) {
                this.webrtc.authenticateMobile(siftToken, pcConfig);
            } else {
                this.webrtc.authenticate(siftToken, pcConfig);
            }
        }).finally(() => {
            this.webrtc.off('authenticated', onAuth);
            this.webrtc.off('error', onAuthError);
        });
    }

    authenticateTwilio(data, pcConfig) {
        let onAuth;
        let onAuthError;
        return new Promise((resolve, reject) => {
            onAuth = resolve;
            onAuthError = reject;
            this.twilio.once('ready', onAuth);
            this.twilio.once('error', onAuthError);
            this.twilio.authenticate(data, pcConfig);
        }).finally(() => {
            this.twilio.off('authenticated', onAuth);
            this.twilio.off('error', onAuthError);
        });
    }

    call(data, metaData, listenOnly) {
        let onSuccess;
        let onError;
        let timeout;
        return new Promise((resolve, reject) => {
            onSuccess = resolve;
            onError = reject;
            if (!this.isActive()) {
                this.loading = true;
                MediaPlayer.setActive(this);
                const { useTwilio = false, call_id: eventId, token } = data;

                fetch(`${config.STREAMS_ENDPOINT}/ice_info?event_id=${eventId}&event_token=${token}`, { mode: 'cors' })
                    .then(r => r.json())
                    .then(({ ice_servers: iceServers }) => {
                        if (useTwilio) {
                            this.callTwilio(data, metaData, listenOnly, { iceServers });
                        } else {
                            this.callGridspace(data, metaData, listenOnly, { iceServers });
                        }
                        this.once('connected', resolve);
                        this.once('disconnected', reject);
                    })
                    .catch(reject);

                timeout = setTimeout(reject, 10000);
            } else {
                resolve();
            }
        })
            .catch(e => {
                this.stop();
                throw e;
            })
            .finally(() => {
                this.loading = false;
                this.off('connected', onSuccess);
                this.off('disconnected', onError);
                clearTimeout(timeout);
            });
    }

    callGridspace(data, metaData, listenOnly, pcConfig) {
        const { siftToken, ...webrtcData } = data;
        const mobile = mobileBridge.enabled(); // && !listenOnly;
        this.authenticateGridspace(siftToken, mobile, pcConfig).then(() => {
            if (mobile) {
                this.webrtc.connectMobile(webrtcData, !!listenOnly, metaData);
            } else {
                this.webrtc.connect(webrtcData, !!listenOnly, metaData);
            }
        });
    }

    callTwilio(data, metaData, listenOnly, pcConfig) {
        this.authenticateTwilio(data, pcConfig).then(() => {
            this.twilio.connect(data, !!listenOnly, metaData);
        });
    }

    record(data, metaData) {
        return this.call({ mode: 'record', ...data }, metaData);
    }

    listen(data, metaData) {
        return this.call({ mode: 'listen', ...data }, metaData, true);
    }

    mute() {
        this.twilio.mute();
        this.webrtc.mute();
    }

    unmute() {
        this.twilio.unmute();
        this.webrtc.unmute();
    }

    // New tone
    tone(tone) {
        const { connection: twilioConnection } = this.twilio;
        const isTwilio = !!twilioConnection;
        if (tone) {
            // Only play for non-twilio calls
            this.dtmf.start(tone, !isTwilio);
        } else if (this.dtmf.tone) {
            this.webrtc.sendDTMF(this.dtmf.tone);
            this.twilio.sendDTMF(this.dtmf.tone);
            this.dtmf.stop();
        }
    }
}

MediaPlayer.webrtc.setMaxListeners(1000);
MediaPlayer.twilio.setMaxListeners(1000);
MediaPlayer.activeEvents.setMaxListeners(1000);
