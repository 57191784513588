import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { get } from 'utils';
import { withEventMediaPlayer } from 'graphql/audioCalls';

import { RecommendedEventUI } from './ui';

export class RecommendedEvent extends PureComponent {
    static displayName = 'RecommendedEventContainer';

    static propTypes = {
        callDate: PropTypes.string,
        equity: PropTypes.objectOf(PropTypes.any),
        hideTooltip: PropTypes.func.isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.any),
        title: PropTypes.string
    };

    static defaultProps = {
        callDate: undefined,
        equity: undefined,
        mediaPlayer: null,
        styles: undefined,
        title: undefined
    };

    render() {
        const { styles, mediaPlayer, title, callDate, equity, hideTooltip } = this.props;
        return (
            <RecommendedEventUI
                mediaPlayer={mediaPlayer}
                styles={styles}
                title={title}
                callDate={callDate}
                ticker={get(equity, 'localTicker')}
                hideTooltip={hideTooltip}
                exchange={get(equity, 'exchange.shortName')}
            />
        );
    }
}

export const RecommendedEventContainer = compose(withEventMediaPlayer({ trackDetails: false }))(RecommendedEvent);
